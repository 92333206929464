
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { ConversationContext } from "../contexts/ConversationContext";
import { RolePromptContext } from "../contexts/RolePromptContext";
import { DatasetContext } from "../contexts/DatasetContext";
import { ChatbotContext } from "../contexts/ChatBotContext";

const Sidebar = () => {

  const { user } = useContext(UserContext)
  const { conversations } = useContext(ConversationContext)
  const { rolePrompts } = useContext(RolePromptContext)
  const { datasets } = useContext(DatasetContext)
  const { chatbots } = useContext(ChatbotContext)

  return (
    <nav className="flex flex-col h-full text-gray-200 w-1/4 max-w-56 w-56 border-r border-gray-600" aria-label="Global">

          
          <div className="h-10  border-b border-gray-600 draggable"></div>

          <div className="h-10 bg-gray-600 border-b border-gray-600  text-md justify-center items-center flex font-bold text-gray-200">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
            </svg>


            <div className="ml-1">SIDEBAR</div>
          </div>

          <div className="p-2 flex flex-col h-full flex-1">
            <NavLink to="/dashboard"
              className={({ isActive}) =>
              isActive ? "px-2 py-2 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 bg-gray-800 text-yellow-100 flex flex-row": "px-2 py-2 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 hover:bg-gray-600 flex flex-row"
            }>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>

                <div className='ml-2'>Dashboard</div>
            </NavLink>

            <NavLink to="/new"
              className={({ isActive}) =>
              isActive ? "px-2 py-2 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 bg-gray-800 text-yellow-100 flex flex-row": "px-2 py-2 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 hover:bg-gray-600 flex flex-row"
            }>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>
                <div className='ml-2'>New project</div>
            </NavLink>

            <div  className="uppercase p-2 rounded-md text-xs font-semibold leading-6 mt-4 text-gray-400 flex flex-row">
              <div className='flex-1'>
                chatbots
              </div>
              <button className='text-gray-400 border border-gray-400 hover:border-green-200 hover:text-green-200 rounded  text-xs m-auto p-0.5 px-1'>
                + ADD
              </button>
            </div>


            <div className="flex-1 overflow-hidden hover:overflow-y-scroll scrollbar ">
              <div className="flex flex-col">
              {chatbots.map((chatbot, key)=>{
                return(
                  <NavLink key={key} to={"/agents/"+chatbot.id}
                    className={({ isActive}) =>
                      isActive ? "px-2 py-1 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 bg-gray-800 text-yellow-100 ease-in-out duration-100": "px-2 py-1 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 hover:bg-gray-600 ease-in-out duration-100"
                    }
                  >
                    <div className="break-all w-48 truncate">
                      {chatbot.name}  
                    </div>   
                  </NavLink>
                )
              })}
              </div>
            </div>

            
            

            <div  className="uppercase p-2 rounded-md text-xs font-semibold leading-6 mt-4 text-gray-400 flex flex-row">
              <div className='flex-1'>
                datasets
              </div>
              <button className='text-gray-400 border border-gray-400 hover:border-green-200 hover:text-green-200 rounded  text-xs m-auto p-0.5 px-1'>
                + ADD
              </button>
            </div>


            <div className="flex-1 overflow-hidden hover:overflow-y-scroll scrollbar ">
              <div className="flex flex-col">
              {datasets.map((dataset, key)=>{
                return(
                  <NavLink key={key} to={"/datasets/"+dataset.id}
                    className={({ isActive}) =>
                      isActive ? "px-2 py-1 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 bg-gray-800 text-yellow-100 ease-in-out duration-100": "px-2 py-1 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 hover:bg-gray-600 ease-in-out duration-100"
                    }
                  >
                    <div className="break-all w-48 truncate">
                      {dataset.title}  
                    </div>   
                  </NavLink>
                )
              })}
              </div>
            </div>


            <div  className="uppercase p-2 rounded-md text-xs font-semibold leading-6 mt-4 text-gray-400 flex flex-row">
              <div className='flex-1'>
                assistants
              </div>
              <button className='text-gray-400 border border-gray-400 hover:border-green-200 hover:text-green-200 rounded  text-xs m-auto p-0.5 px-1'>
                + ADD
              </button>
            </div>
            <div className="flex-1 overflow-hidden hover:overflow-y-scroll scrollbar ">
              <div className="flex flex-col">
              {rolePrompts.map((rolePrompt, key)=>{
                return(
                  <NavLink key={key} to={"/role_prompts/"+rolePrompt.id}
                    className={({ isActive}) =>
                      isActive ? "px-2 py-1 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 bg-gray-800 text-yellow-100 ease-in-out duration-100": "px-2 py-1 max-h-12 overflow-hidden rounded-md text-sm font-semibold leading-6 hover:bg-gray-600 ease-in-out duration-100"
                    }
                  >
                    <div className="break-all w-48 truncate">
                      {rolePrompt.title}  
                    </div>   
                  </NavLink>
                )
              })}
              </div>
            </div>

          <div className="flex-row flex mt-4">
            <div className="mr-1">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <div className="flex-1 flex-col text-blue-200">
              <div >
                {user.email.split(".")[0]}
              </div>
              <div className="text-xs text-gray-400">
                Free tier
              </div>
            </div>
            <div className="m-auto">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
          </div>
        </div>
      </nav>
  );
}

export default Sidebar;
