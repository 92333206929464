import React, { useState, useContext } from "react";
import ChatHistory from "../components/Chat/ChatHistory";
import { ChatContext } from "../contexts/ChatContext";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { RolePromptContext } from "../contexts/RolePromptContext";
import { DatasetContext } from "../contexts/DatasetContext";
import ChatPending from "../components/Chat/ChatPending";
import AssistantSelector from "../components/Chat/AssistantSelector";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";


const Chat = () => {
  let { id } = useParams();
  const navigate = useNavigate()

  
  const { chatHistory, submitPrompt } = useContext(ChatContext);
  const { rolePrompts } = useContext(RolePromptContext)
  const { datasets } = useContext(DatasetContext)

  const [query, setQuery] = useState("");
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(false);
  const [rolePromptId, setRolePromptId] = useState(rolePrompts[0]?.id? rolePrompts[0].id: 1)

  const handleInputchange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = () => {
    setSearching(true);
    search(query);
  };

  const search = (query) => {
    setQuery("");
    setError("");

    submitPrompt(query, id, rolePromptId, datasets[0].id, (chatId, error) => {
      setSearching(false);
      if(!id){
        navigate('/conversations/'+chatId)
      }
      
      if (error) {
        console.log(error)
        setError(error);
      }
    });

  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (!searching) {
        handleSubmit();
      }
    }
  };

  const submitButtonStyle = searching || query == '' ? "text-gray-500 border-gray-500":"bg-green-200 text-gray-800 border-gray-800"
  
  const chatItemIndex = chatHistory.findIndex(chat => chat.id == id);
  let selectedChatHistory = []
  if(chatItemIndex !== -1){
    selectedChatHistory = chatHistory[chatItemIndex].chat_messages
  }
  return (
    <div className="flex flex-col bg-gray-800 max-h-screen h-screen">

      <div className="flex flex-row h-20">
        <Header />
      </div>

      <div className="flex flex-row bg-gray-800 flex-1 h-full">
        <Sidebar/>
        <div className="flex flex-col flex-1 ">
          <div className="flex flex-col grow overflow-hidden w-full">
            

            {/* Chat history and status/failures */}
            <div className=" flex flex-1 flex-col-reverse overflow-y-scroll justify-between scrollbar">
              <div className="">
                { selectedChatHistory.length > 0 && <ChatHistory chatHistory={selectedChatHistory} /> }
                { searching && <ChatPending/> }
                { error && <div>{error}</div> }
              </div>
            </div>

            {/* Input box */}
            <div className="px-4 pb-4 flex flex-row">
              <div className="relative w-full">

                <textarea
                  type="text"
                  onKeyDown={(e) => {
                    handleKeyDown(e);
                  }}
                  onChange={(e) => {
                    handleInputchange(e);
                  }}
                  className="overflow-y-scroll scrollbar-hide pr-12 bg-gray-700 block px-3 pt-11 pb-4 w-full text-lg text-gray-200 rounded-lg border border-gray-600 focus:outline-gray-400 focus:outline-2 focus:outline  placeholder-gray-400"
                  value={query}
                  placeholder="Ask me anything"
                />

                <button
                  type="button"
                  className={`absolute bottom-2 right-2 p-1 text-sm font-medium  ${submitButtonStyle} rounded border border-gray-300 hover:bg-green-400`}
                  onClick={() => handleSubmit()}
                  disabled={searching || query == ''}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                  </svg>
                </button>

                <div className="flex flex-row absolute top-px left-px  w-full rounded-xl">
                  <div className=" flex-1 flex-row flex p-2 bg-gray-700 rounded-xl">
                    {rolePrompts.length > 0 && <AssistantSelector data={rolePrompts} value={rolePromptId} onChange={(value)=>{setRolePromptId(value)}}/>}

                    <button
                      type="button"
                      className={`ml-2 flex flex-row p-1 px-2 text-sm font-medium text-gray-500 border-gray-600 rounded border border-gray-300 hover:border-gray-400 hover:bg-gray-800 hover:text-gray-400`}
                      onClick={() => handleSubmit()}
                      disabled={searching || query == ''}
                    >
                      <div className="mr-1">{datasets.length > 0 && datasets[0].title}</div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
                      </svg>
                    </button>
                  </div>
                  <div className="w-1 "></div>
                  

                </div>
                
              </div>
            </div>

          </div>
        </div>

      </div>
      <div className="h-20">
        <Footer />
      </div>

    </div>
  );
};

export default Chat;