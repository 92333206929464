import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import UserProvider from "./contexts/UserContext";
import ConversationProvider from './contexts/ConversationContext';
import RolePromptProvider from './contexts/RolePromptContext';
import DatasetProvider from './contexts/DatasetContext';
import ChatbotProvider from './contexts/ChatBotContext';
import { BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider>
        <ChatbotProvider>
            <ConversationProvider>
                <RolePromptProvider>
                    <DatasetProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </DatasetProvider>
                </RolePromptProvider>
            </ConversationProvider>
        </ChatbotProvider>
    </UserProvider>
);

