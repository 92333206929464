const retryFetch = (url, delay, maxRetries = 3) => {
  return new Promise((resolve, reject) => {
    let retries = 0;
    function doFetch() {
      fetch(url)
        .then(response => {
          if (response.ok) {
            resolve(response);
          } else {
            throw new Error('Fetch error');
          }
        })
        .catch(error => {
          retries++;

          if (retries <= maxRetries) {
            setTimeout(() => {
              doFetch();
            }, delay);
          } else {
            reject(error);
          }
        });
    }
    doFetch();
  });
}

export default retryFetch;
