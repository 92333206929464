import React, { useState, useContext, useEffect } from 'react';
import { config } from '../config';

const ChatbotProvider = props => {

  const [chatbots, setChatbots] = useState([]);

  const getChatbots = () => {
    fetch(config.apiUrl + `/chatbots/`, {
      method: 'GET',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json'
      }
    })
    .then(response=>response.json()) // convert response to json
    .then(response =>{

      //handle response
      const sortedChatbots = response.sort((a, b) => a.id > b.id ? 1 : -1);
      setChatbots(sortedChatbots)
    })
  }

  useEffect(()=>{
    getChatbots()
  },[])
  

  return (
    <ChatbotContext.Provider value={{chatbots, getChatbots}}>
      {props.children}
    </ChatbotContext.Provider>
  );

};

export const ChatbotContext = React.createContext();
export const ChatbotConsumer = ChatbotContext.Consumer;

export default ChatbotProvider;