import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DatasetContext } from "../contexts/DatasetContext";
import Uploader from "../components/Uploader";
import { config } from "../config";
import UploaderWeb from "../components/UploaderWeb";

const Dataset = () => {
  let { id } = useParams()
  const {datasets}  = useContext(DatasetContext)
  const [dataSources, setDataSources] = useState([])
  // find the role prompt by id
  const dataset = datasets[datasets.findIndex(item=>item.id == id)]
  const fetchDataSources = (dataset_id) => {
    fetch(config.apiUrl + `/datasets/${dataset_id}/sources`, {
      method: 'GET',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json'
      }
    })
    .then(response=>response.json()) // convert response to json
    .then(response =>{
      setDataSources(response)
    })
  }

  let groupedDatasources = []
  dataSources.forEach(datasource => {
    // see if already exists in list
    const existsIndex = groupedDatasources.findIndex((item)=>item.title === datasource.title)
    if (existsIndex == -1){
      datasource.contexts = [datasource.combined]
      datasource.name = datasource.title.replace(/^.*[\\\/]/, '') 
      groupedDatasources.push(datasource)
    } else {
      groupedDatasources[existsIndex].contexts.push(datasource.combined)
    }
  })

  useEffect(()=>{
    fetchDataSources(id)
  },[id])

  const deleteSource = (title) => {
    var postData = JSON.stringify({
      "action": "delete",
      "source_title" : title
    });
    fetch(config.apiUrl + `/datasets/${id}/sources/`, {
      method: 'POST',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json'
      },
      body: postData
    })
    .then(response=>response.json()) // convert response to json
    .then(response =>{
      console.log(response)
      fetchDataSources(id)
    })
  }
 
  return (
    
    <div className='flex flex-1 h-screen p-6 bg-gray-800'>
      {dataset && <div className="flex flex-col flex-1">
        <div className="mb-2 flex flex-row">
          <div className="flex flex-1 flex-col">
            <div className="text-xl font-bold text-gray-200">
              {dataset.title}
            </div>

            <div className="font-bold text-gray-500">
              {dataset.description}
            </div>
          </div>
          <div className="flex-row flex">
            

            <div className="text-xl font-bold text-gray-200 flex flex-1 justify-center items-center ml-4 hover:text-red-300">
              <div className = 'flex justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="my-6 ">
            {/* <Uploader dataset_id={dataset.id} onComplete={fetchDataSources} /> */}
            
            <UploaderWeb dataset_id={dataset.id} onComplete={fetchDataSources}/>
        </div>

        <div className="flex flex-col flex-1 overflow-hidden hover:overflow-y-scroll scrollbar  ">
          {groupedDatasources.map((dataSource)=>{
            return(
              <div className="bg-gray-700 rounded p-2 m-1 text-gray-400 text-sm font-semibold hover:bg-gray-600 hover:text-gray-300 flex flex-row justify-between">
                <div className="flex flex-row">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  {dataSource.name}
                  </div>
                  <button className="hover:text-red-600" onClick={()=>{deleteSource(dataSource.title)}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                  </button>

              </div>
              )
          })}
        </div>

      </div>}
      
    </div>
  );
  
}

export default Dataset;
