import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { ConversationContext } from './ConversationContext';
import { config } from '../config';

export const ChatContext = React.createContext();
export const ChatConsumer = ChatContext.Consumer;

const ChatProvider = props => {
  const {user} = useContext(UserContext)
  const {conversations, getConversations} = useContext(ConversationContext)
  
  const [chatHistory, setChatHistory] = useState([]);
  
  useEffect(()=>{
    setChatHistory(conversations)
  },[conversations])


  const submitPrompt = (message, chatId, rolePromptId, datasetId, callback) => {
    try {

    
      if(chatId){
        const newMessage = {message_type: "prompt", message: message}
        // find index of chat by the ID we are focused on
        const chatItemIndex = chatHistory.findIndex(chat => chat.id == chatId);
        // get the chat by index
        var chatItem = chatHistory[chatItemIndex]
        // get messages from the chat
        const chatMessages = chatItem.chat_messages
        // update the messages array to include our new message
        const updatedChatMessages = [...chatMessages, newMessage]
        // assign our new messages array back to chat item
        chatItem.chat_messages = updatedChatMessages
        chatHistory[chatItemIndex] = chatItem
        setChatHistory(chatHistory)

        var postData = JSON.stringify({
          "message_type" : "prompt",
          "message" : message,
          "conversation_id": chatId,
          "role_prompt_id": rolePromptId,
          "dataset_id": datasetId
        });

        setTimeout(()=>{
          fetch(config.apiUrl + '/prompt/', {
            method: 'POST',
            headers: {
              accept: 'application.json',
              'Content-Type': 'application/json'
            },
            body: postData,
          })
          .then(response=>response.json()) // convert response to json
          .then(response =>{

            //handle response
            if(response){
              const newMessage = response
              // find index of chat by the ID we are focused on
              const chatItemIndex = chatHistory.findIndex(chat => chat.id == chatId);
              // get the chat by index
              var chatItem = chatHistory[chatItemIndex]
              // get messages from the chat
              const chatMessages = chatItem.chat_messages
              // update the messages array to include our new message
              const updatedChatMessages = [...chatMessages, newMessage]
              // assign our new messages array back to chat item
              chatItem.chat_messages = updatedChatMessages
              chatHistory[chatItemIndex] = chatItem
              setChatHistory(chatHistory)
              callback(chatId, null)
            }
          })
          .catch(error => {
            console.log(error)
            callback(chatId, 'failed to get response')
          })
        },2000)

      } else { // we don't have chat id, create a new one

        
        var postData = JSON.stringify({
          "title" : message
        });
    
        fetch(config.apiUrl + `/users/${user.id}/conversations/`, {
          method: 'POST',
          headers: {
            accept: 'application.json',
            'Content-Type': 'application/json'
          },
          body: postData,
        })
        .then(response=>response.json()) // convert response to json
        .then(response =>{

          
          // find index of chat by the ID we are focused on
          const newChat = response
          const updatedChats = [...chatHistory, newChat]

          const chatItemIndex = updatedChats.findIndex(chat => chat.id == newChat.id);
          // get the chat by index
          var chatItem = updatedChats[chatItemIndex]
          // get messages from the chat
          const chatMessages = chatItem.chat_messages
          // update the messages array to include our new message
          const newMessage = {message_type: "prompt", message: message}
          const updatedChatMessages = [...chatMessages, newMessage]
          // assign our new messages array back to chat item
          chatItem.chat_messages = updatedChatMessages
          updatedChats[chatItemIndex] = chatItem
          setChatHistory(updatedChats)

          
          //handle response
          const chatId = response.id
          if(chatId ){
            var postData = JSON.stringify({
              "message_type" : "prompt",
              "message" : message,
              "conversation_id": chatId,
              "role_prompt_id": rolePromptId,
              "dataset_id": datasetId
            });
            
            setTimeout(()=>{


            
              fetch(config.apiUrl + '/prompt/', {
                method: 'POST',
                headers: {
                  accept: 'application.json',
                  'Content-Type': 'application/json'
                },
                body: postData,
              })
              .then(response=>response.json()) // convert response to json
              .then(response =>{
        
                //handle response
                if(response){
                  const newMessage = response 
                  // find index of chat by the ID we are focused on
                  const chatItemIndex = updatedChats.findIndex(chat => chat.id == chatId);
                  // get the chat by index
                  var chatItem = updatedChats[chatItemIndex]
                  // get messages from the chat
                  const chatMessages = chatItem.chat_messages
                  // update the messages array to include our new message
                  const updatedChatMessages = [...chatMessages, newMessage]
                  // assign our new messages array back to chat item
                  chatItem.chat_messages = updatedChatMessages
                  updatedChats[chatItemIndex] = chatItem
                  setChatHistory(updatedChats)
                  callback(chatId , null)
                  getConversations()
                }
              })
            },2000)
          }
        })
      }
    } catch (error) {
      console.log(error)
      callback(null, error)
    }

  }



  return (
    <ChatContext.Provider value={{chatHistory, submitPrompt, setChatHistory}}>
      {props.children}
    </ChatContext.Provider>
  );

};

export default ChatProvider;