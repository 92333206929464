

const Footer = () =>{
    return (
      <div className="justify-center items-center flex ">
        <div className="text-gray-600 my-1 text-sm">
          Hype Platforms, Inc.
        </div>
      </div>
    );
  }
  
  export default Footer;