import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { config } from '../config';

export const DatasetContext = React.createContext();
export const DatasetConsumer = DatasetContext.Consumer;

const DatasetProvider = props => {
  const {user} = useContext(UserContext)
  const [datasets, setDatasets] = useState([]);

  const getDatasets = () => {
    if(Object.keys(user).length !== 0){

      fetch(config.apiUrl + `/datasets/`, {
        method: 'GET',
        headers: {
          accept: 'application.json',
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + localStorage.getItem('accessToken')
        }
      })
      .then(response=>response.json()) // convert response to json
      .then(response =>{

        //handle response
        const sortedDatasets = response.sort((a, b) => a.id > b.id ? 1 : -1);
        setDatasets(sortedDatasets)
      })
    }
  }

  useEffect(()=>{
    getDatasets()
    // eslint-disable-next-line
  },[user])

  return (
    <DatasetContext.Provider value={{datasets, getDatasets}}>
      {props.children}
    </DatasetContext.Provider>
  );

};

export default DatasetProvider;