import React, {useState} from "react";

const ChatResponseItemSources = ({ sources }) => {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false)
  return (
    <div>
      <div className='mt-3 rounded-br-2xl flex flex-row border-t border-gray-500 pt-3 text-sm flex-wrap'>
        {sources.map((source)=>{
          const filename = source.title.replace(/^.*[\\\/]/, '')
          return (
            <button className='flex flex-row my-1 mr-3 text-gray-400 border rounded-xl px-2 py-1 border-gray-400 hover:border-yellow-100 hover:text-yellow-100' onClick={()=>{window.electron.openFile(source.title)}}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
              </svg>
              {filename}
            </button>
          )
        })}

        {/* <button className="mr-3 text-gray-600 border rounded-xl px-3 py-1 border-gray-500 hover:bg-gray-800 hover:text-gray-200"
          onClick={()=>{setShowAdditionalInfo(!showAdditionalInfo)}}
        >
          ?
        </button> */}

      </div>

      {showAdditionalInfo && 
        <div className="flex flex-col flex-1 mt-2">
          {sources.map((source)=>{
            const filepath = source.title
            return (
              <div className="border border-gray-200 my-1 p-2 break-all rounded-lg">
                <div><b>filepath:</b> {filepath} </div>
                <div><b>similarity:</b>  {source.similarity} </div>
                <div><b>context:</b> {source.combined} </div>
              </div>
            )
          })}
        </div>
      }
    </div>
  );
};

export default ChatResponseItemSources;