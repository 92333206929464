import React, { useContext } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { UserContext } from "../../contexts/UserContext";
import {
  ActionIcon,
  Box,
  Code,
  CopyButton,
  Table,
  Tooltip,
} from "@mantine/core";

const ChatUserItem = ({item}) =>{
  const {user} = useContext(UserContext)
    return (
      <div className="my-4 flex flex-1 flex-row p-2  transition flex flex-1 flex-row pl-2 p-2 hover:border-gray-500 transition bg-gray-700 mr-1 ml-4 rounded border border-gray-800 ">
        <div className="bg-gray-500 w-12 h-12 justify-center flex rounded p-1 text-gray-400">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-10 h-10">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>


        </div>
        <div className="px-3 pr-4 text-gray-300">
          <div  className="font-bold leading-6">
            
            {user.validated?user.username: "Me, a humble little founder.."}
          </div>
          <div>
            <ReactMarkdown
          children={item.override? item.override_message : item.message}
          remarkPlugins={[remarkGfm]}
          className='markdown'
          components={{
            table: ({ node, ...props }) => (
              <Table verticalSpacing="sm" highlightOnHover {...props} />
            ),
            code: ({ node, inline, ...props }) =>
              inline ? (
                <Code {...props} />
              ) : (
                <Box sx={{ position: "relative", marginTop: '1em', marginBottom: '1em' }}>
                  <Code block {...props} sx={{backgroundColor: '#223344', color: '#CACACA'}} />
                  <CopyButton value={String(props.children)} >
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? "Copied" : "Copy"}
                        position="left"
                      >
                        <ActionIcon
                          sx={{ position: "absolute", top: 4, right: 4 }}
                          onClick={copy}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                          </svg>

                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Box>
              ),
          }}
        />
          </div>
        </div>
      </div>
    );
  }
  
  export default ChatUserItem;