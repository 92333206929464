import React, { useState, useContext } from "react";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {
  ActionIcon,
  Box,
  Code,
  CopyButton,
  Table,
  Tooltip,
} from "@mantine/core";
import ChatResponseItemSources from "./ChatResponseItemSources";

const ChatResponseItem = ({item, botInfo}) =>{
    const [copyVisible, setCopyVisible] = useState(false)

    return (
      <div className="mt-4 flex flex-1 flex-row p-2  bg-gray-700 ml-4 mr-1 rounded border border-gray-800 hover:border-gray-500 relative" 
        onMouseOver={()=>{setCopyVisible(true)}} onMouseOut={()=>{setCopyVisible(false)}}
      >
        {copyVisible && 
          <div className="absolute top-0 right-0">
            <CopyButton value={item.message} >
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copied" : "Copy"}
                  position="left"
                >
                  <ActionIcon
                    sx={{ position: "absolute", top: 4, right: 4 }}
                    onClick={copy}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                    </svg>

                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </div>
        }
        

        <div className="w-12 h-12 justify-center flex text-gray-700">
          
          <img className="rounded-xl" src={botInfo.avatar_url}/>

        </div>
        <div className="px-3 pr-4 text-gray-300 flex-1 flex flex-col">
          
        <div  className="font-bold leading-6">{botInfo.name}</div>
        <ReactMarkdown
          children={item.message}
          remarkPlugins={[remarkGfm]}
          className='markdown'
          components={{
            table: ({ node, ...props }) => (
              <Table verticalSpacing="sm" highlightOnHover {...props} />
            ),
            code: ({ node, inline, ...props }) =>
              inline ? (
                <Code {...props} />
              ) : (
                <Box sx={{ position: "relative", marginTop: '1em', marginBottom: '1em' }}>
                  <Code block {...props} sx={{backgroundColor: '#223344', color: '#CACACA'}} />
                  <CopyButton value={String(props.children)} >
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? "Copied" : "Copy"}
                        position="left"
                      >
                        <ActionIcon
                          sx={{ position: "absolute", top: 4, right: 4 }}
                          onClick={copy}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                          </svg>

                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Box>
              ),
          }}
        />

          {item.sources && item.sources.length > 0 && 
            <ChatResponseItemSources sources={item.sources}/>
          }
        </div>
      </div>
    );
  }
  
  export default ChatResponseItem;