import { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate} from "react-router-dom";
import retryFetch from "./lib/retryFetch";

import LoadingScreen from "./components/LoadingScreen";
import { UserContext } from './contexts/UserContext';
import ChatProvider from "./contexts/ChatContext";
import Chat from "./pages/Chat";
import RolePrompt from "./pages/RolePrompt";
import Dataset from "./pages/Dataset";
import ChatBot from "./pages/ChatBot";
import Dashboard from "./pages/Dashboard"
import { config } from "./config";
import Login from "./pages/Login";
import About from "./pages/About";

console.log(config)
const App = () => {
  const {user, getUser} = useContext(UserContext)

  const [ready, setReady] = useState(false)
  const [loadingError, setLoadingError] = useState(false)

  useEffect(()=>{
    retryFetch(config.apiUrl, 2000, 30).then((response)=>{
      setReady(true)
      getUser()
      
    }).catch((error)=>{
      setLoadingError('service did not start in time')
    })
  },[])
  
  if(!ready){
    return(
      <LoadingScreen error={loadingError}/>
    )
  } else {
    //check if we have a user, if we do proceed, if not, get user
    if(Object.keys(user).length !== 0) {
        return (

            <ChatProvider>
              
                {user.validated?
                  <Routes>
                    <Route path="/agents" element={<ChatBot />} />
                    <Route path="/agents/:id" element={<ChatBot />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/new" element={<Chat />} />
                    <Route path="/conversations/:id" element={<Chat />} />
                    <Route path="/role_prompts/:id" element={<RolePrompt />} />
                    <Route path="/datasets/:id" element={<Dataset />} />
                    <Route path="/150m3THin6" element={<Login/>} />
                    <Route path="/about" element={<About/>} />
                    <Route
                      path="/"
                      element={<Navigate to="/agents/1" replace />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/dashboard" replace />}
                    />
                  </Routes>
                :
                  <Routes>
                    <Route path="/about" element={<About/>} />
                    <Route path="/agents" element={<ChatBot />} />
                    <Route path="/agents/:id" element={<ChatBot />} />
                    <Route path="/150m3THin6" element={<Login/>} />
                    <Route
                      path="/"
                      element={<Navigate to="/agents/1" replace />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to="/" replace />}
                    />
                  </Routes>
                }
              
            </ChatProvider>
        );
      
    } else {
      return (
        <div className="bg-gray-800 h-screen w-screen justify-center items-center flex-1 flex">
          <div className=" p-4 rounded bg-gray-700 text-gray-300">
          Loading identity..
          </div>
        </div>
      )
    }
  }
}

export default App;
