import { useState, useContext } from "react"
import { UserContext } from "../contexts/UserContext"
import { useNavigate } from "react-router-dom"

const Login = () => {
  const navigate = useNavigate()
  const {login} = useContext(UserContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)

    login( username, password, ()=>{
      navigate('/new')
    })
    
  }

  const handleKeyDown = (e) => {
    
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div className='flex flex-1 items-center justify-center h-screen bg-gray-800'>
      <div>
        <input
          type="text"
          onChange={(e) => {
            handleUsernameChange(e);
          }}
          className="p-2 bg-gray-700 placeholder-gray-400"
          value={username}
          placeholder="Username"
        />

        <input
          type="password"
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
          onChange={(e) => {
            handlePasswordChange(e);
          }}
          className="p-2 bg-gray-700 placeholder-gray-400"
          value={password}
          placeholder="Password"
        />
        <button
          type="button"
          className={` p-1 text-sm font-medium rounded border border-gray-300 hover:bg-gray-400`}
          onClick={(e) => handleSubmit(e)}
        >
          submit
        </button>
      </div>
    </div>
  );
}

export default Login;
