import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { config } from '../config';
export const RolePromptContext = React.createContext();
export const RolePromptConsumer = RolePromptContext.Consumer;

const RolePromptProvider = props => {
  const {user} = useContext(UserContext)
  const [rolePrompts, setRolePrompts] = useState([]);

  const getRolePrompts = () => {
    if(Object.keys(user).length !== 0){

      fetch(config.apiUrl + `/role_prompts/`, {
        method: 'GET',
        headers: {
          accept: 'application.json',
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + localStorage.getItem('accessToken')
        }
      })
      .then(response=>response.json()) // convert response to json
      .then(response =>{

        //handle response
        const sortedRolePrompts = response.sort((a, b) => a.id > b.id ? 1 : -1);
        setRolePrompts(sortedRolePrompts)
      })
    }
  }
  useEffect(()=>{
    getRolePrompts()
    // eslint-disable-next-line
  },[user])
  

  return (
    <RolePromptContext.Provider value={{rolePrompts, getRolePrompts}}>
      {props.children}
    </RolePromptContext.Provider>
  );

};

export default RolePromptProvider;