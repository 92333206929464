import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import { config } from '../config';
export const ConversationContext = React.createContext();
export const ConversationConsumer = ConversationContext.Consumer;


const ConversationProvider = props => {
  const {user} = useContext(UserContext)
  const [conversations, setConversations] = useState([]);

  const getConversations = () => {
    if(Object.keys(user).length !== 0){
      fetch(config.apiUrl + `/users/${user.id}/conversations/`, {
        method: 'GET',
        headers: {
          accept: 'application.json',
          'Content-Type': 'application/json'
        }
      })
      .then(response=>response.json()) // convert response to json
      .then(response =>{

        //handle response
        const sortedConversations = response.sort((a, b) => a.id < b.id ? 1 : -1);
        setConversations(sortedConversations)
      })
    }
  }
  useEffect(()=>{
    getConversations()
    // eslint-disable-next-line
  },[user])
  

  return (
    <ConversationContext.Provider value={{conversations, getConversations}}>
      {props.children}
    </ConversationContext.Provider>
  );

};

export default ConversationProvider;