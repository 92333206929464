import React, { useState, useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ChatHistory from "../components/Chat/ChatHistory";
import { useParams } from "react-router-dom";
import ChatPending from "../components/Chat/ChatPending";
import Header from "../components/Header";
import ChatDemoLeftSidebar from "../components/ChatDemo/ChatDemoLeftSidebar";
import Footer from "../components/Footer";
import { config } from "../config";
import ChatError from "../components/Chat/ChatError";
import ChatInputBox from "../components/Chat/ChatInputBox";
import ChatInputBoxModified from "../components/Chat/ChatInputBoxModified";

const ChatBot = () => {
  let { id } = useParams();

  const [chatHistory, setChatHistory] = useState([])
  const [loadingConverstation, setLoadingConversation] = useState(false)
  const [botInfo, setBotInfo] = useState(null)
  const [conversationId, setConversationId] = useState(null)
  const [botSelected, setBotSelected] = useState(false)

  // get bot info
  // get conversation history id using botid + user auth info
  // if conversation doesn't exist with bot already, create a new one
  // 

  const fetchConversations = (chat_bot_id) => {
    fetch(config.apiUrl + `/chatbots/${id}/conversations/`, {
      method: 'GET',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      }
    })
    .then(response=>response.json()) // convert response to json
    .then(response =>{
      if(response.length>0){
        setConversationId(response[response.length-1].id)
        setChatHistory(response[response.length-1].chat_messages)
        if(response[response.length-1].chat_messages < 1){
          getGreeting(response[response.length-1].id)
        }
      } else {
        // we don't have chat history, create one
        createConversation(chat_bot_id)
      }
    })
  }

  const fetchData = (chat_bot_id) => {
    try {
      fetch(config.apiUrl + `/chatbots/${chat_bot_id}/`, {
        method: 'GET',
        headers: {
          accept: 'application.json',
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + localStorage.getItem('accessToken')
        }
      })
      .then(response=>response.json()) // convert response to json
      .then(response =>{
        console.log(response)
        setBotInfo(response)
        fetchConversations(id)
      }).catch((error)=>{
        setBotSelected(false)
      })
    } catch (error) {
      setBotSelected(false)
    }
  }

  useEffect(()=>{
    if(id) {
      setBotSelected(true)
      fetchData(id)
    } else {
      setBotSelected(false)
    }
    
  },[id])

  const createConversation = (chat_bot_id) => {
    setLoadingConversation(true)
    var postData = JSON.stringify({
      "title" : "prompt",
      "description" : "description",
      "chat_bot_id": chat_bot_id,
    });
    setTimeout(()=>{
      fetch(config.apiUrl + `/chatbots/${chat_bot_id}/conversations/`, {
        method: 'POST',
        headers: {
          accept: 'application.json',
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + localStorage.getItem('accessToken')
        },
        body: postData,
      })
      .then(response=>response.json()) // convert response to json
      .then(response =>{
        console.log(response)
        //handle response
        if(response){
          setConversationId(response.id)
          setChatHistory(response.chat_messages)
          setLoadingConversation(false)
          getGreeting(response.id)
        }
  
      })
      .catch(error => {
        console.log(error)
        setError('failed to start new conversation!')
      })
    },1000)
    
  }

  const getGreeting = (conversation_id) => {
    var postData = JSON.stringify({});

    fetch(config.apiUrl + `/prompt/greeting/${conversation_id}/`, {
      method: 'POST',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      },
      body: postData,
    })
    .then(response=> {
      const newReply = {message_type: "response", message: ""}
      const chatHistory = [newReply]
      
      setChatHistory(chatHistory)
      handleStream(response.body, (data)=>{
        const updatedItem = {...chatHistory[0], message: data};
        const newChatHistory = [...chatHistory]
        newChatHistory[0] = updatedItem
        setChatHistory(newChatHistory)
      })
      .then(()=>{
        console.log('got greeting')
      })
    }) // convert response to json
    
    .catch(error => {
      console.log(error)
      setError('failed to get greeting!')
    })

    
  }

  const startOver = () => {
    setChatHistory([])
    createConversation(botInfo.id)
  }
  
  const submitPrompt = (message, chatId, override, override_message, callback) => {
    try {
      var postData = override?
        JSON.stringify({
          "message_type" : "prompt",
          "message" : message,
          "conversation_id": chatId,
          "override": override,
          "override_message": override_message
        })
      :
        JSON.stringify({
          "message_type" : "prompt",
          "message" : message,
          "conversation_id": chatId,
        })

      const newMessage = {message_type: "prompt", message: override? override_message:message}
      chatHistory.push(newMessage)
      const newReply = {message_type: "response", message: ""}
      chatHistory.push(newReply)
      const newHistory = chatHistory
      setChatHistory(newHistory)
      
      fetch(config.apiUrl + '/prompt/', {
        method: 'POST',
        headers: {
          accept: 'application.json',
          'Content-Type': 'application/json'
        },
        body: postData,
      })
      .then(response=>{
        handleStream(response.body, (data)=>{
          const updatedItem = {...chatHistory[chatHistory.length -1], message: data};
          const newChatHistory = [...chatHistory]
          newChatHistory[chatHistory.length -1] = updatedItem
          setChatHistory(newChatHistory)
        })
        .then(()=>{
          console.log('done')
          callback()
        })
      }) // convert response to json
      .catch(error => {
        console.log(error)
        setError('failed to send message')
        setSearching(false)
      })

    } catch (error) {
      console.log(error)
      setError('failed to send message')
      setSearching(false)
    }

  }

  const handleStream = async (readableStream, callback) => {
    const reader = readableStream.getReader();
    const decoder = new TextDecoder()
    var chunks = "";
    let done, value;
    while (!done) {
      ({ value, done } = await reader.read());
      if (done) {
        return chunks;
      }
      value = decoder.decode(value, {stream: true})
      chunks += value
      callback(chunks)
    }
  }
  
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(false);

  const search = (query, override = false, override_message = "") => {
    setError("");
    setSearching(true)
    submitPrompt(query, conversationId, override, override_message, ()=>{
      setSearching(false)
    })
  };
  
  return (
    <div className="absolute inset-0">
   
    <div className="flex flex-col bg-gray-800 h-full">

      <div className="block lg:hidden">
        <Header/>
      </div>

      <div className="flex flex-row flex-1 justify-center h-screen lg:pt-2 lg:pb-2 ">

        <div className="hidden lg:block w-56">
          <ChatDemoLeftSidebar/>
        </div>
          
        <div className="flex flex-col w-full lg:w-2/3 2xl:w-2/5 border-t border-b lg:border lg:rounded-xl border-gray-700">
          {botSelected & !loadingConverstation?
            <div className="flex flex-col grow overflow-hidden w-full h-48">
              {botInfo && 
                <div className="flex justify-center items-center p-2 text-gray-600 text-xs">
                  Convos will only be temporarily saved, do not expect permanence. 
                </div>
              }

              {/* Chat history and status/failures */}
              <div className=" flex flex-1 flex-col-reverse overflow-y-scroll scrollbar justify-between mr-1 mb-4">
                <div className="">
                  { chatHistory.length > 0 && <ChatHistory chatHistory={chatHistory} botInfo={botInfo}/> }
                  {/* { searching && <ChatPending/> } */}
                  { error && <ChatError error={error}/> }
                </div>
              </div>

              {/* Input box */}
              <ChatInputBoxModified search={search} searching={searching} done={chatHistory.length>2} startOver={startOver}/>

            </div>
            :<div className="flex flex-col flex-1 justify-center items-center">
              <div className=" p-4 rounded bg-gray-700 text-gray-300 justify-center items-center flex flex-col">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 animate-spin">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
                </svg>
                <div className="text-2xl mt-2">
                  Connecting you to a VC..
                </div>
              </div>
            </div>
          }
        </div>

        <div className="hidden lg:block w-56">
          
        </div>

      </div>

        
      </div>
    </div>
  );
};

export default ChatBot;