

const ChatError = ({error}) => {
    return (
        <div className="flex flex-1 flex-row justify-between my-1 ">
            <div className="flex flex-1 flex-row p-2 hover:bg-gray-600 transition bg-gray-800 ml-4 mr-1 rounded border border-gray-700 text-gray-600 font-semibold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>

                <div>{error}</div>
            </div>
        </div>
    );
};

export default ChatError;