

const ChatPending = () => {
    return (
        <div className="flex flex-1 flex-row justify-between my-1 ">
            <div className="flex flex-1 flex-row pl-2 p-2 hover:bg-gray-600 transition bg-gray-700 ml-4 mr-1 rounded border border-gray-600">
                
                <svg className="w-12 h-12 p-2 bg-yellow-600 text-gray-200 rounded" viewBox="0 0 105 105" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="none">
                    <circle cx="12.5" cy="12.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="0s" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
                        <animate attributeName="fill-opacity"
                        begin="100ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="52.5" cy="12.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="300ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="52.5" cy="52.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="600ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="92.5" cy="12.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="800ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="92.5" cy="52.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="400ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="12.5" cy="92.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="700ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="52.5" cy="92.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="500ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                    <circle cx="92.5" cy="92.5" r="12.5">
                        <animate attributeName="fill-opacity"
                        begin="200ms" dur="1s"
                        values="1;.2;1" calcMode="linear"
                        repeatCount="indefinite" />
                    </circle>
                </svg>
            </div>
        </div>
    );
};

export default ChatPending;