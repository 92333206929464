

const About = () => {
  

  return (
    <div className='flex flex-1 items-center justify-center h-screen bg-gray-800'>
      <div>
        About HypePlatforms, Inc.
      </div>
    </div>
  );
}

export default About;
