
const Dashboard = () => {
  return (
    <div className='flex flex-1 items-center justify-center h-screen bg-gray-800'>
      <div> Dashboard coming soon!</div>
    </div>
  );
}

export default Dashboard;
