import { NavLink } from "react-router-dom";

const Header = () =>{
    return (
      <div className="justify-center items-center flex">
        <div className="w-full lg:w-2/3 2xl:w-2/5 flex flex-col p-2">

        <div className="flex flex-row items-center justify-center rounded-md ">

        </div>
            <div className="flex flex-row rounded-md">
              <div className="flex items-center justify-center rounded-md px-1 py-1">
                
                <div className="ml-1 flex text-2xl text-gray-200 tracking-tight font-bold">
                  Synth <div className="text-yellow-600">.ai</div>
                </div>
              </div>

              <div className="flex flex-1"/>

              <NavLink to="/about"
                className={"flex rounded-md text-sm font-semibold leading-6 hover:bg-gray-600 hover:text-gray-200  text-gray-500 w-10"}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>
              </NavLink>

              <NavLink to="/new"
                className={"flex rounded-md text-sm font-semibold leading-6 hover:bg-gray-600 hover:text-gray-200 text-gray-500 w-10"}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full p-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                  </svg>
              </NavLink>

            </div>


        </div>
        
      </div>
    );
  }
  
  export default Header;