import { useParams } from "react-router-dom";
import { useContext } from "react";
import { RolePromptContext } from "../contexts/RolePromptContext";

const RolePrompt = () => {
  let { id } = useParams()
  const { rolePrompts } = useContext(RolePromptContext)

  // find the role prompt by id
  const rolePrompt = rolePrompts[rolePrompts.findIndex(item=>item.id == id)]

  return (
    <div className='flex flex-col flex-1 items-center justify-center bg-gray-800 h-screen'>
      <div className="p-4 bg-gray-700 text-gray-300 rounded">
        <div> <b>Title:</b> {rolePrompt.title} </div>
        <div> <b>Prompt:</b> {rolePrompt.prompt} </div>
      </div>
    </div>
  );
  
}

export default RolePrompt;
