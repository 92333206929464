import React from "react";
import ChatResponseItem from "./ChatResponseItem";
import ChatUserItem from "./ChatUserItem";

const ChatHistory = ({ chatHistory, botInfo }) => {
  
  return (
    <>
      {chatHistory.map((item, key) => {
        return (
          <div key={key}>
            {item.message_type === 'prompt' &&
              <ChatUserItem item={item}/>
            }
            {item.message_type === 'response' &&
              <ChatResponseItem item={item} botInfo={botInfo}/>
            }
          </div>
        );
      })}
    </>
  );
};

export default ChatHistory;