import React, {useState} from "react";

const SelectBox = ({ data, value, onChange }) => {
  
  const [dropdownVisible, setDropDownVisible] = useState(false)

  const onSelect = (value) => {
    setDropDownVisible(false)
    onChange(value)
  }
  const selectedRole = data.find(role => {
    return role.id === value
  })
  //console.log(selectedRole)

  return (
    <div className="relative">
      <button   onClick={()=>{setDropDownVisible(!dropdownVisible)}} className="flex flex-row p-1 px-2 text-sm font-medium text-gray-500 border-gray-600 rounded border border-gray-300 hover:border-gray-400 hover:bg-gray-800 hover:text-gray-400" type="button">
        {selectedRole.title}
        <svg className="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      
      {dropdownVisible && 
        <div className="absolute bottom-0 z-10 -translate-y-12 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
          <div className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton" >
            {data.map((item, key) => {
              return (        
                <button key={key} onClick={()=>{onSelect(item.id)}} className="py-1 px-3 hover:bg-gray-500 w-full text-left">
                    {item.title}
                </button>
              );
              
            })}
          </div>
        </div>
      }
    </div>
  );
};

export default SelectBox;