import React, { useState } from 'react'
import { config } from '../config';

const UploaderWeb = ({dataset_id, onComplete}) => {

  const [uploading, setUploading] = useState(false)

  const handleFileChange = (e) => {
    //prompt user for folder
    uploadFile(e.target.files[0])
  };

  const uploadFile = (selectedFile) => {
    setUploading(true)

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append('filepath', selectedFile.name);
    formData.append('dataset_id', dataset_id);

    setTimeout(()=>{
      fetch(config.apiUrl + '/ingest/', {
        method: 'POST',
        body: formData,
      })
      .then(response=>response.json()) // convert response to json
      .then(response =>{
  
        //handle response
        if(response){
          console.log(response)
          onComplete(dataset_id)
        }
      })
      setUploading(false)
    },1000)


  }



  return (
    <div>

      {uploading?
        <div>Uploading file..</div>
      :
        <div>
          <input type="file" onChange={(e)=>{handleFileChange(e)}} />
        </div>
      }
      

    </div>

  )

}


export default UploaderWeb;