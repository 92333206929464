import React, { useEffect, useState } from 'react';
import { config } from '../config';

export const UserContext = React.createContext();
export const UserConsumer = UserContext.Consumer;


const UserProvider = props => {

  const [user, setUser] = useState({});

  const login = (username, password, callback) => {
    var postData = JSON.stringify({
      "username" : username,
      "password" : password,
    });
    
    fetch(config.apiUrl + '/auth/token/', {
      method: 'POST',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json'
      },
      body: postData,
    })
    .then(response=>response.json()) // convert response to json
    .then(response =>{
      console.log(response)
      localStorage.setItem('accessToken', response.access_token)
      setUser({})
      getUser().then(
        callback()
      )
    })
  }
  


  const getUser = async () => {
    return fetch(config.apiUrl + `/auth/me/`, {
      method: 'GET',
      headers: {
        accept: 'application.json',
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      }
    })
    .then((response)=>{
      if(response.status == 401){

        // unauthed, get authed
        fetch(config.apiUrl + `/auth/register/`, {
          method: 'POST',
          headers: {
            accept: 'application.json',
            'Content-Type': 'application/json'
          }
        })
        .then(response=>response.json()) // convert response to json
        .then(response=>{
          
          localStorage.setItem('accessToken', response.access_token)

          fetch(config.apiUrl + `/auth/me/`, {
            method: 'GET',
            headers: {
              accept: 'application.json',
              'Content-Type': 'application/json',
              'Authorization': 'bearer ' + response.access_token
            }
          })
          .then(response=>response.json()) // convert response to json
          .then(response=>{
            setUser(response)
            //save to local storage also
          })

          //save to local storage also
        })
      } else {
        return response.json()
      } 
    })
    .then(response =>{
      if(response) {
        setUser(response)
      }
      
    })
  }

  useEffect(()=>{
    getUser()
  },[])

  return (
    <UserContext.Provider value={{user, login, getUser}}>
      {props.children}
    </UserContext.Provider>
  );

};

export default UserProvider;